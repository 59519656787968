import React from 'react';

const AboutMe = () => (
    <div id='aboutme'>
      <h3>What I'm Working On</h3>
      <ul>
        <li>
          <a href='https://microgreenmanager.com'>Microgreen Manager</a>
        </li>
      </ul>
    </div>
  );
  
  export default AboutMe;
  